<template>
  <!-- Desc: View: product & category targeting for - Prouct targeting adgroups  -->
  <div
    class="card min-height-card u-flex-direction-column u-width-100 u-display-flex adgroupProductAds"
  >
    <div>
      <component
        :is="addCustomProductsPanelConfig.component"
        v-if="showAddCustomProductPanel"
        v-bind="addCustomProductsPanelConfig.props"
        class="adgroupProductAds"
        v-on="addCustomProductsPanelConfig.events"
      >
        <div
          v-if="showErrorMessage"
          slot="right-table-footer"
          class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
        >
          <rb-icon
            class="u-color-orange-base rb-icon--small"
            icon="info-circle-fill"
          />
          <span>
            {{ showErrorMessage }}
          </span>
        </div>
      </component>
    </div>
    <div
      v-if="confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <component
        :is="confirmStatusChange.component"
        v-bind="confirmStatusChange.props(this)"
      />
    </div>
    <div v-if="adjustBids">
      <div class="u-font-size-6 u-spacing-p-l">
        <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
          ADJUST BID ({{ selections.length }} SKU{{
            selections.length > 1 ? `'s` : ''
          }}
          selected)
        </div>
        <radioSelectionWithInput
          class="u-spacing-pt-m"
          :radio-config="bidChangeConfig"
          default-radio-selected="set_new_bid"
          :error-message="errorMessage"
          :show-input-title="showInputTitle"
          :input-title="inputTitle"
          :bid-suggestion="bidSuggestion"
          @onUpdate="onBidUpdates"
        />
      </div>
      <div
        class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
      >
        <footerButtons
          :disable-left-btn="
            !(bidValue && bidValue.inputValue && bidValue.inputValue.length) ||
            errorMessage.length > 0
          "
          class="u-spacing-ph-l"
          :button-size="'s'"
          :hollow-hover-button-left="false"
          left-button-type="filled"
          btn-left-text="Apply"
          @onClickBtnRight="closeActionBar"
          @onClickBtnLeft="onSaveBid"
        />
      </div>
    </div>
    <div
      v-if="selections.length && !adjustBids && !confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
      @widgetParamsChanged="widgetParamsChanged"
    />
  </div>
</template>
<script>
import {
  deepReplaceObjectValues,
  findDimensionValueFromWidgetParams
} from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import customActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import Vue from 'vue';
import { actionPanelAddCustomProductsConfig } from '@/pages/entity_details/configs/amazon/actionConfigs.js';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';

const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');
const reviewDataComp = () =>
  import('@/pages/entity_details/configs/walmart/components/reviewData.vue');
const confirmStatusChangeComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/approveRec.vue'
  );
const reviewStatusComponent = () =>
  import('@/pages/entity_details/configs/walmart/components/reviewStatus.vue');
const iconTextActions = () =>
  import('@/components/widgets/iconTextActions.vue');

export default {
  components: {
    tableWrapper,
    actionsBar,
    footerButtons,
    radioSelectionWithInput
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    const context = this;
    return {
      defaultSearchText: '',
      bidValue: null,
      getState: null,
      selections: [],
      adjustBids: null,
      stateChangeRequestPayload: null,
      addCustomProductsRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      context,
      actionIdMethodMap: {},
      widget: {},
      allowedActions: null,
      bidSuggestion: { show: false },
      tabConfig: null,
      showAddCustomProductPanel: false,
      reviewData: null,
      gridOptions: {
        context: { componentParent: this },
        domLayout: 'normal'
      },
      confirmStatusChange: null,
      showErrorMessage: null,
      bidChangeConfig: [
        {
          title: 'Set new bid',
          type: 'input',
          value: 'set_new_bid'
        },
        {
          title: 'Increase bid',
          value: 'increase_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease bid',
          value: 'decrease_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ],
      errorMessage: '',
      showInputTitle: true,
      inputTitle: 'Current Bid'
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    addCustomProductsPanelConfig() {
      return (
        this.tabConfig?.actionPanelAddCustomProductsConfig?.(this) ||
        actionPanelAddCustomProductsConfig(this)
      );
    },
    headerOptions() {
      const that = this;
      const obj = {};
      if (this.tabMetadata?.widgets[0]) {
        const isReviewDataVisible =
          this?.tabConfig?.headerOptions?.(this).length > 0;
        const displayType = isReviewDataVisible ? 'flex' : 'grid';
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            headerOptions: [
              {
                ui_component: 'rb-button',
                props: {
                  clickFn: that.addCustomProductsButtonClick,
                  text: 'Add Custom Products',
                  type: 'filled',
                  disabled: this.campaignInfo?.data?.reviewStatus === 'pending'
                },
                class: 'u-spacing-mr-xl'
              },
              ...(this?.tabConfig?.headerOptions?.(this) || [])
            ],
            enableDownload: false,
            enableManageColumns: !this.selections.length,
            manageColumnGridPosition: !isReviewDataVisible ? 4 : undefined,
            manageColumnFlexOrder: isReviewDataVisible ? 1000 : 300,
            headerContainerGridStyles: {
              'grid-template-columns': 'auto 1fr auto auto',
              'align-items': 'center',
              display: this.selections.length ? 'none' : displayType
            },
            searchParams: {
              value: that.defaultSearchText,
              show: true,
              placeholder: 'Find a product',
              searchDimension:
                this.tabConfig?.searchText || 'ams_cm_asin_search_text'
            },
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: this.handleSelectionChange
            },
            rowHeight: 70,
            gridOptions: this.gridOptions
          }
        };
        if (
          this.$route?.params?.entityType === 'sb' &&
          this.$store.getters.getRetailer === 'amazon'
        ) {
          obj[this.tabMetadata.widgets[0]].props.headerOptions = [];
          obj[this.tabMetadata.widgets[0]].props.rowSelection = {};
        }
      }
      return obj;
    }
  },
  watch: {
    tableDataComponentKey: function (nv, ov) {
      if (nv !== ov) {
        this.tabConfig?.apiConfigs?.fetchMetadata?.(this);
      }
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.stateChangeRequestPayload = tabConfig?.stateChange?.requestPayload;
    this.addCustomProductsRequestPayload =
      tabConfig?.addCustomProducts?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    Vue.component('customActionPanel', customActionPanel);
    Vue.component('reviewDataComp', reviewDataComp);
    Vue.component('confirmStatusChangeComponent', confirmStatusChangeComponent);
    Vue.component('reviewStatusComponent', reviewStatusComponent);
    Vue.component('iconTextActions', iconTextActions);
    let orderByList = [];
    orderByList = this.setOrderByList();
    this.widget = {
      ':widget': this.tabMetadata.widgets[0],
      ':orderByList': orderByList,
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInDimensionNameValueList: {
        ':adgroup_id': this.entityId,
        ':ad_group_id': this.entityId
      },
      replaceEntityInOperationsObject: {
        ':system': `campaign_${this.parentEntityId}`
      }
    };
    this.allowedActions = tabConfig.allowedActions;
  },
  methods: {
    setOrderByList() {
      const retailer = this.$store.getters.getRetailer;
      const entityType = this.$route?.params?.entityType;
      const orderByListConfig = {
        amazon:
          entityType !== 'sb'
            ? [{ dimension: 'ams_cm_asin_total_spend', direction: 'DESC' }]
            : [],
        instacart: [{ dimension: 'instacart_cm_sku_spend', direction: 'DESC' }],
        walmart: [{ dimension: 'walmart_cm_skus_ad_spend', direction: 'DESC' }]
      };
      return orderByListConfig[`${retailer}`] || [];
    },
    reloadComponent(boolReload = true) {
      if (boolReload) {
        this.$store.dispatch(
          this.tabConfig?.apiConfigs?.fetchTableDataComponentKeyGetter
        );
      }
    },
    widgetParamsChanged(value) {
      const searchText = findDimensionValueFromWidgetParams(
        value,
        this.tabMetadata?.widgets?.[0],
        'ams_cm_asin_search_text'
      );
      this.defaultSearchText = searchText?.dimensionValue || '';
    },
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      if (actionId === 'editStatus') {
        this.confirmStatusChange = {
          component: 'confirmStatusChangeComponent',
          props: (vueRef) => ({
            config: {
              heading: `EDIT STATUS(${vueRef.selections.length} item${
                vueRef.selections.length > 1 ? 's' : ''
              } selected)`,
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              message: `Status for selected item${
                vueRef.selections.length > 1 ? 's' : ''
              } will be ${dropDownSelection[0].value}`,
              apply: () => {
                this.callStateChange(dropDownSelection, selections);
              },
              handleCancel: () => {
                this.closeActionBar();
              }
            }
          })
        };
      } else {
        if (actionId === 'adjustBids') {
          this.tabConfig?.checkForSuggestedBid?.(this);
        }
        this[actionId] = true;
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.adjustBids = null;
      this.bidSuggestion = { show: false };
      this.confirmStatusChange = null;
    },
    callStateChange(dropDownSelection, selections) {
      if (this.tabConfig?.changeStatus) {
        return this.tabConfig.changeStatus(this, dropDownSelection, selections);
      }
      const payloadArr = [];
      for (const selection of selections) {
        if (
          selection?.data?.ams_cm_adgroup_status === dropDownSelection[0].value
        ) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(this.stateChangeRequestPayload);
          const reqValues = {
            ':campaignId': this.parentEntityId,
            ':adgroupId': this.entityId,
            ':oldState': selection?.data?.ams_cm_asin_status,
            ':state': dropDownSelection[0].value,
            ':pageUrl': window.location.href,
            ':ad_id': selection?.data?.ad_id,
            ':keywordText':
              selection.data.ams_cm_targeting_asin_asin_name || 'NA',
            ':asin_id': selection.data.ams_cm_asin_asin || 'NA',
            ':adgroupName': this.campaignInfo?.data?.name || 'NA'
          };
          if (
            this.tabConfig?.stateChange?.requestPayload?.actionType ===
            'instacartProductMetadataChange'
          ) {
            reqValues[':oldState'] = selection?.data?.instacart_cm_sku_status;
            reqValues[':adgroupName'] =
              selection?.data?.instacart_cm_sku_sku_name;
            reqValues[':ad_id'] = selection?.data?.sku_id;
          }
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          payloadArr.push(payload);
        }
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Asin status',
          plural: 'Asin Statuses',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    addProducts(selections) {
      const payloadArr = [];
      for (const selection of selections) {
        const payload = cloneDeep(this.addCustomProductsRequestPayload);
        const reqValues = {
          ':campaignId': this.parentEntityId,
          ':adgroupId': this.entityId,
          ':asin_title': selection.product_title,
          ':pageUrl': window.location.href,
          ':image_url': selection.image_url,
          ':asin_id': selection.asin || 'NA',
          ':adgroupName': this.campaignInfo?.data?.name || 'NA'
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Campaign Product',
          plural: 'Campaign Products',
          action: 'added',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: false,
        entityName,
        routeData: this.$route
      });
      this.closeActionBar();
      this.showAddCustomProductPanel = false;
    },
    addCustomProductsButtonClick() {
      this.closeActionBar();
      this.showAddCustomProductPanel = true;
    },
    transformBid(oldBid, selection, key) {
      let delta = parseFloat(this.bidValue.inputValue);
      if (this.bidValue.radioValue === 'set_new_bid') {
        if (this.bidValue.inputValue === 'YY') {
          return parseFloat(oldBid);
        }
        if (this.bidValue.inputValue === 'XX') {
          return parseFloat(selection.data?.[key]);
        }
        return delta;
      }
      if (this.bidValue?.dropdownValue === 'percentage') {
        delta = oldBid * (delta / 100);
      }
      if (this.bidValue?.radioValue === 'increase_bid') {
        return parseFloat((oldBid + delta).toFixed(2));
      } else {
        return parseFloat((oldBid - delta).toFixed(2));
      }
    },
    onBidUpdates(updatedValues) {
      this.tabConfig?.onBidUpdates?.(this, updatedValues);
    },
    onSaveBid() {
      return this.tabConfig?.onSaveBid?.(this);
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px !important;
}
.adgroupProductAds div[col-id^='walmart_cm_skus_review_status'].ag-cell {
  margin: 0;
  padding: 0;
}
.adgroupProductAds .custom-entity .left-table div[col-id^='sku_price'].ag-cell,
.adgroupProductAds
  .custom-entity
  .left-table
  div[col-id^='weeks_of_coverage'].ag-cell {
  text-align: right !important;
}
.adgroupProductAds .edit_suggested_campaign_keyword_targeting {
  bottom: 6.5rem;
}
.adgroupProductAds .inputClassForEntityTaggingSearch {
  display: flex;
  align-items: center;
}
</style>
